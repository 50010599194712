<template src="./index.html" />

<script>
import {
  BButton, BCol, BRow, BForm, BBreadcrumb, BCard, BAvatar, BBadge, BPagination,
  BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BOverlay, BContainer, BModal,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import vSelect from 'vue-select'
import FormArticle from '@/views/portal/articles/FormArticles.vue'
import { mapActions, mapGetters } from 'vuex'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { ApiCode } from '@/const/api'
import { getUser } from '@/auth/utils'

export default {
  name: 'Articles',
  components: {
    BModal,
    BButton,
    BCol,
    BRow,
    BForm,
    BBreadcrumb,
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    vSelect,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    FormArticle,
    BOverlay,
    BContainer,
  },

  data() {
    return {
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        organizationId: getUser().orgId,
        categoryId: null,
        search: null,
        status: 1,
        sort: null,
      },
      table: {
        columns: [
          { field: 'rowNum', label: 'STT', type: 'string' },
          { field: 'categoryName', label: 'Loại bài viết', type: 'string' },
          { field: 'name', label: 'Tiêu đề', type: 'string' },
          { field: 'header', label: 'Tóm tắt', type: 'string' },
          {
            field: 'action', label: '', type: 'string', width: '100px',
          },
        ],
        data: [],
      },
      paginationOptions: {
        enabled: true,
      },
      optCategory: [],
      isLoading: false,
      itemsPerPageOptions: [10, 20, 50],
      dataSendForm: {},
    }
  },
  computed: {
    // calculate data from init data
    ...mapGetters({
      articles: 'portalArticle/articles',
      total: 'portalArticle/total',
      articleCategories: 'dropdown/articleCategories',
    }),
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.ARTICLES)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.ARTICLES)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.ARTICLES)
    },
  },
  async created() {
    // init data FROM API
    await this.getArticleCategories({ organizationId: getUser().orgId })
    this.optCategory = this.articleCategories
    if (this.articleCategories.length > 0) {
      this.filter.categoryId = this.articleCategories[0].value
    }
    await this.getData()
  },
  methods: {
    // handle even
    ...mapActions({
      getArticles: 'portalArticle/getArticles',
      updateArticles: 'portalArticle/updateArticles',
      createArticle: 'portalArticle/createArticles',
      getArticleCategories: 'dropdown/getArticleCategories',
      deleteArticle: 'portalArticle/deleteArticle',
    }),

    updateParams(newProps) {
      this.serverParams = { ...this.filter, ...newProps }
    },

    async onPageChange(params) {
      this.updateParams({ currentPage: params.currentPage })
      await this.getData()
    },

    async onPerPageChange(params) {
      this.updateParams({ itemsPerPage: params.currentPerPage })
      await this.getData()
    },

    async onColumnFilter(params) {
      this.updateParams(params)
      await this.getData()
    },

    async onChangeCategory() {
      await this.getData()
    },

    async getData() {
      await this.getArticles(this.filter)
      this.table.data = this.articles
    },

    async btnSearchClick() {
      await this.getData()
    },

    async onEditArticle(data) {
      this.dataSendForm = data
      this.$bvModal.show('modal-article')
    },

    async onDeleteArticle(data) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa  <span class="text-danger">${data.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteArticle(data.id)
            if (response) {
              const { code, message } = response
              if (code) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getData()
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            await this.getData()
            this.isLoading = false
          }
        }
      })
    },

    addArticle() {
      this.dataSendForm = {}
      this.$bvModal.show('modal-article')
    },

    async saveData(data) {
      this.isLoading = true
      const res = await this.createArticle(data)
      this.isLoading = false
      if (res.code === ApiCode.SUCCESS) {
        await this.getData()
        this.showToast('Cập nhật thành công', 'CheckIcon', 'success')
        this.$bvModal.hide('modal-article')
      } else {
        this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
      }
    },

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
